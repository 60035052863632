import React from 'react';

import './Disclaimer.scss';

function Disclaimer() {
  return (
    <div className="mn_siteDisclaimer">
      All Rapid Rewards&reg; rules and regulations apply and can be found
      at <a href="https://Southwest.com/rrterms" target="_blank" title="Opens in new window">Southwest.com/rrterms</a>.
    </div>
  );
}

export default Disclaimer;

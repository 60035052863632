import Loadable from 'react-loadable';

const LoadableWrapper = pageComponentPath => Loadable({
  loader: () => import(`./pages${pageComponentPath}`),
  loading: () => (null),
});

export const routesConfig = [
  { route: '/h____.htm?', PageComponent: LoadableWrapper('/Home') },
];

import { FOOTER_BANNER_GET_SUCCESS, FOOTER_BANNER_GET_FAILURE } from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  placement: [],
};

const footerBanner = (state = initialState, action) => {
  switch (action.type) {
    case FOOTER_BANNER_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placement: action.payload.response[0],
      };
    }
    case FOOTER_BANNER_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placement: [],
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === FOOTER_BANNER_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }

      return state;
    }
  }
};

export default footerBanner;

import { FIRST_FAVORITE_MODAL_VIEW } from '../actions/types';

export const firstFavoriteModalGaActionEvents = {
  [FIRST_FAVORITE_MODAL_VIEW]: () => (
    {
      category: 'Favorites Opt In Prompt',
      action: 'Display',
      label: ' ',
      value: 1,
    }
  ),
};

export const firstFavoriteModalClickAndHoverEvents = [
  {
    selector: '.mn_firstFavoriteModal .mn_favoriteTurnOnAlerts',
    click: {
      category: 'Favorites Opt In Prompt',
      action: 'Opt-in',
    },
    label: 'Turn on alerts',
    value: 1,
  },
  {
    selector: '.mn_firstFavoriteModal .mn_modalClose',
    click: {
      category: 'Favorites Opt In Prompt',
      action: 'Popup close',
    },
    label: 'X click',
    value: 1,
  },
  {
    selector: '.mn_firstFavoriteModal .mn_favoriteNoThanks',
    click: {
      category: 'Favorites Opt In Prompt',
      action: 'Opt Out',
    },
    label: (element) => element.innerText,
    value: 1,
  },
  {
    selector: '.mn_firstFavoriteModal .mn_viewFavorites',
    click: {
      category: 'Favorites Opt In Prompt',
      action: 'Popup close',
    },
    label: ' Favorite more stores click',
    value: 1,
  },
];

import React from 'react';
import { useSelector } from 'react-redux';

import { selectSiteUrl } from 'core/selectors/app';

import './MainLogo.scss';

function MainLogo() {
  const siteUrl = useSelector(selectSiteUrl);
  return (
    <div className="mn_brandLogo">
      <a href={siteUrl} title="Southwest Rapid Rewards Shopping">
        <span>Southwest Rapid Rewards Shopping</span>
      </a>
    </div>
  );
}

export default MainLogo;

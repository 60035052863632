import React from 'react';
import { useSelector } from 'react-redux';
import { selectSiteUrl, selectLoginUrl } from 'core/selectors/app';
import { selectUserName, selectDisplayableUserId, selectUserIsIdentified } from 'core/selectors/user';
import { selectActiveMQ } from 'core/modules/MediaQueries/selectors';
import { useDropdown } from 'core/hooks/useDropdown';

export function UserInfoSectionWithDropdown() {
  const siteUrl = useSelector(selectSiteUrl);
  const loginUrl = useSelector(selectLoginUrl);
  const userName = useSelector(selectUserName);
  const displayableUserId = useSelector(selectDisplayableUserId);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const {
    hideSubnav, showSubnav, isFocused, dropdownClassName,
  } = useDropdown();

  const activeMQ = useSelector(selectActiveMQ);

  if (!userIsIdentified) {
    return (
      <a href={loginUrl} className="mn_button" data-ga-track-login>Sign in</a>
    );
  }

  if (['XL', 'XXL'].includes(activeMQ)) {
    return (
      <div
        onMouseEnter={showSubnav}
        onMouseLeave={hideSubnav}
        onBlur={hideSubnav}
        className={`mn_navigationDropdownTrigger ${dropdownClassName}`}
        data-section-name="Account"
      >
        <button
          className="mn_navigationItem"
          aria-expanded={isFocused}
          aria-label="Account"
          onFocus={showSubnav}
        >
          <i className="mn_SVGIconAccount" aria-hidden="true" />My Account
        </button>
        <div className="mn_navigationDropdown">
          <div className="mn_highlitedSection">
            <div className="mn_userNameWrap">Welcome, <span className="mn_userName">{userName}</span></div>
            <div className="mn_userId">Rapid Rewards<sup>&reg;</sup> account #{displayableUserId}</div>
          </div>
          <ul>
            <li><a href={`${siteUrl}/ai____.htm`}>My account</a></li>
            <li><a href={`${siteUrl}/foryou____.htm`}>Offers for you</a></li>
            <li><a href={`${siteUrl}/logoutUser.php?lUrl=/`}>Log out</a></li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className="mn_userInfo">
      <span className="mn_userNameWrap">Welcome, <span className="mn_userName">{userName}</span></span>
      <span className="mn_userId">Rapid Rewards<sup>&reg;</sup> account #{displayableUserId}</span>
    </div>
  );
}

export default UserInfoSectionWithDropdown;

import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { footerBanner as orgConfig } from 'org/configs/modules.config';
import { FOOTER_BANNER_GET_SUCCESS, FOOTER_BANNER_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_group_id: 1057,
        content_type_id: 40,
        fields: [
          'id',
          'assets',
          'clickUrl',
        ],
        limit: 1,
      },
      options: {
        success: FOOTER_BANNER_GET_SUCCESS,
        failure: FOOTER_BANNER_GET_FAILURE,
        cache: {
          isEnabled: true,
          isPublishedData: true,
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);

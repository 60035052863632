const VALUE_NOT_SET = '(not set)';

export const headerClickAndHoverEvents = [
  {
    selector: '.mn_pageHeader .mn_brandLogo a',
    click: {
      category: 'Header Navigation',
      action: 'Southwest logo click',
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_pageHeader .mn_headerExtraRewardsSection .mn_navigationDropdownTrigger .mn_navigationItem',
    hover: {
      category: 'Header Navigation',
      action: 'Extra points icon hover',
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_pageHeader .mn_helpSection .mn_navigationDropdownTrigger .mn_navigationItem',
    hover: {
      category: 'Header Navigation',
      action: 'FAQ icon hover',
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_pageHeader .mn_userSection .mn_navigationDropdownTrigger .mn_navigationItem',
    hover: {
      category: 'Header Navigation',
      action: 'Account icon hover',
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_pageHeader .mn_favoritesSection .mn_navigationItem',
    click: {
      category: 'Header Navigation',
      action: 'Favorites icon click',
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_pageHeader .mn_userSection a.mn_button',
    click: {
      category: 'Header Navigation',
      action: 'Sign up Click',
    },
    label: VALUE_NOT_SET,
  },
];

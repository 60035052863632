import { createSelector } from 'reselect';

const selectFooterBanner = state => state.footerBanner;

export const selectFooterBannerConfig = createSelector(
  selectFooterBanner,
  footerBanner => footerBanner.config,
);

export const selectFooterBannerIsLoading = createSelector(
  selectFooterBanner,
  footerBanner => footerBanner.isLoading,
);

export const selectFooterBannerIsLoaded = createSelector(
  selectFooterBanner,
  footerBanner => footerBanner.isLoaded,
);

export const selectFooterBannerPlacement = createSelector(
  selectFooterBanner,
  footerBanner => footerBanner.placement,
);

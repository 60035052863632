import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserIsIdentified } from 'core/selectors/user';
import { selectSiteUrl, selectLoginUrl } from 'core/selectors/app';

import './TopNavigation.scss';

function TopNavigation() {
  const siteUrl = useSelector(selectSiteUrl);
  const loginUrl = useSelector(selectLoginUrl);
  const userIsIdentified = useSelector(selectUserIsIdentified);

  return (
    <div className="mn_topNavigationWrap">
      <ul className="mn_topNavigation" aria-label="Top navigation">
        {userIsIdentified ?
          <li><a className="mn_logoutLink" href={`${siteUrl}/logoutUser.php?lUrl=/`}>Log out</a></li> :
          <li>Not a Rapid Rewards<sup>&reg;</sup> Member?{' '}
            <a className="mn_signUpLink" href={loginUrl} target="_blank" title="Opens in new window">Sign up</a>
          </li>
        }
        <li><a href={`${siteUrl}/faq____.htm`}>FAQs</a></li>
        <li>
          <a
            href="https://www.southwest.com/rapidrewards/overview?int=MALL-RRHP-LINK"
            target="_blank"
            title="Opens in new window"
          >
            Southwest.com<sup>&reg;</sup>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default TopNavigation;

import React from 'react';
import { useSelector } from 'react-redux';

import { useGetNavigation } from 'core/modules/Navigation/Nav/hooks';
import NavItem from 'core/modules/Navigation/NavPartials/NavItem/NavItem';

import { selectSiteUrl } from 'core/selectors/app';

import './NavOffersTOF.scss';

export function NavOffersTOF() {
  const siteUrl = useSelector(selectSiteUrl);
  const { navItemsFeatured, navItemsNonFeatured } = useGetNavigation();

  return (
    <div className="mn_navOffersTOF">
      <h2>Shop by offers</h2>
      <section className="mn_navOffersList">
        <ul className="mn_navDropdownMenu">
          {navItemsFeatured && navItemsFeatured.map(navItem => (
            <NavItem
              key={navItem.title.toString()}
              title={navItem.title}
              url={navItem.url}
              extraRewardsLabel="Extra points"
            />
          ))}
          {navItemsNonFeatured && navItemsNonFeatured.map(navItem => (
            <NavItem
              key={navItem.title.toString()}
              title={navItem.title}
              url={navItem.url}
              extraRewardsLabel="Extra points"
            />
          ))}
          <NavItem title="All offers" url={`${siteUrl}/sp____.htm`} />
          <NavItem title="Free shipping" url={`${siteUrl}/fs____Free%20Shipping.htm`} />
        </ul>
      </section>
    </div>
  );
}

export default NavOffersTOF;

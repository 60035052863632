import { PRIMARY_HERO_VIEW } from '../actions/types';

export const primaryHeroGaActionEvents = {
  [PRIMARY_HERO_VIEW]: () => (
    {
      category: 'VHP CTA',
      action: 'View',
      label: 'Sign in - primary',
    }
  ),
};

export const primaryHeroClickEvents = [
  {
    selector: '.mn_primaryHero .mn_button',
    click: {
      category: 'VHP CTA',
      action: 'Click',
    },
    label: 'Sign in - primary',
  },
];

import React from 'react';
import { useSelector } from 'react-redux';

import { selectUserName, selectUserIsIdentified, selectDisplayableUserId } from 'core/selectors/user';
import { selectSiteUrl, selectLoginUrl } from 'core/selectors/app';

import './SidebarWelcomeMessage.scss';

function MessageForIdentifiedUser({ siteUrl, userName, displayableUserId }) {
  return (
    <div className="mn_mobileNavWelcomeMessage">
      <h3>
        <strong>Welcome, <span>{userName}</span></strong>
        <br />
        Rapid Rewards<sup>&reg;</sup> account #{displayableUserId}
      </h3>
      <a className="mn_logoutLink" href={`${siteUrl}/logoutUser.php?lUrl=/`}>Log out</a>
    </div>
  );
}

function MessageForNonIdentifiedUser({ loginUrl }) {
  return (
    <div className="mn_mobileNavWelcomeMessage mn_nonIdentified">
      <p>Already a member? <a href={loginUrl} data-ga-track-login="">Login</a></p>
      {/* eslint-disable-next-line max-len */}
      <p>Not a member?<a href="https://www.southwest.com/account/enroll/enroll-member?f=zBNRSHPGAA1110000zz&int=MALLSWALINKENROLL111101">Sign up</a></p>
    </div>
  );
}

function SidebarWelcomeMessage() {
  const siteUrl = useSelector(selectSiteUrl);
  const userName = useSelector(selectUserName);
  const displayableUserId = useSelector(selectDisplayableUserId);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const loginUrl = useSelector(selectLoginUrl);

  return userIsIdentified ?
    <MessageForIdentifiedUser siteUrl={siteUrl} userName={userName} displayableUserId={displayableUserId} />
    :
    <MessageForNonIdentifiedUser loginUrl={loginUrl} />;
}

export default SidebarWelcomeMessage;

import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import detectButton from 'core/utils/helpers/detectButton';
import { isDesktop, isChrome, isEdge } from 'core/utils/helpers/browser';

import { addExternalAttributesIfNeeded } from 'core/utils/helpers/externalLink';
import {
  selectSiteUrl,
  selectPrivacyLinkEnabled,
  selectDataPrivacyPageEnabled,
  selectClientHostedDPSRedirectURL,
  selectTermsAndConditionsURL,
  selectPrivacyPolicyUrl,
  selectToolbarConfig,
} from 'core/selectors/app';
import { useGetNavigation } from 'core/modules/Navigation/Nav/hooks';
import { useGetCategories } from 'core/modules/Navigation/NavCategories/hooks/useGetCategories';
import { selectUserIsIdentified } from 'core/selectors/user';
import MainNavItem from 'core/modules/Navigation/NavPartials/MainNavItem/MainNavItem';
import NavItem from 'core/modules/Navigation/NavPartials/NavItem/NavItem';
import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';
import './Navigation.scss';

function Navigation({ sectionId }) {
  const siteUrl = useSelector(selectSiteUrl);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const enablePrivacyNoticeLink = useSelector(selectPrivacyLinkEnabled);
  const enableDonotSharePage = useSelector(selectDataPrivacyPageEnabled);
  const clientHostedDPSRedirectURL = useSelector(selectClientHostedDPSRedirectURL);
  const termsAndConditionsURL = useSelector(selectTermsAndConditionsURL);
  const privacyPolicyUrl = useSelector(selectPrivacyPolicyUrl);
  const toolbarConfig = useSelector(selectToolbarConfig);
  const { navItemsFeatured, navItemsNonFeatured } = useGetNavigation();
  const { categories } = useGetCategories();
  const { chromeDownloadUrl, enableButtonMarketing } = toolbarConfig;

  const [shouldShowButtonLink, setShouldShowButtonLink] = useState(false);

  useEffect(() => {
    detectButton.then((hasButton) => {
      setShouldShowButtonLink(!hasButton && chromeDownloadUrl && isDesktop && (isChrome || isEdge)
        && enableButtonMarketing);
    });
  }, []);

  return (
    <div className="mn_navBar">
      <div className="mn_mainNav mn_contentSection" data-section-id={sectionId}>
        <MainNavItem title="Home" url={`${siteUrl}`} />

        {categories &&
          <MainNavItem className="mn_navCategories" title="Categories">
            {categories.map(category => (
              <NavItem
                key={category.id}
                title={category.name}
                label={`Visit the ${category.name} page`}
                url={`${siteUrl}/${category.searchFriendlyName}/bc_${category.id}___.htm`}
              />
            ))}
          </MainNavItem>
        }

        <MainNavItem title="Offers">
          {navItemsFeatured && navItemsFeatured.map(navItem => (
            <NavItem key={navItem.title.toString()} title={navItem.title} url={navItem.url} hideExtraRewardsLabel />
          ))}
          {navItemsNonFeatured && navItemsNonFeatured.map(navItem => (
            <NavItem key={navItem.title.toString()} title={navItem.title} url={navItem.url} hideExtraRewardsLabel />
          ))}
          <NavItem title="All offers" url={`${siteUrl}/sp____.htm`} />
          <NavItem title="Free shipping" url={`${siteUrl}/fs____Free%20Shipping.htm`} />
        </MainNavItem>

        <MainNavItem title="Stores" url={`${siteUrl}/b____.htm`} />

        {navItemsFeatured && navItemsFeatured.map(navItem => (
          <Fragment key={navItem.title.toString()}>
            <MainNavItem title={navItem.title} url={navItem.url} />
          </Fragment>
        ))}

        <MainNavItem title="How it works" url={`${siteUrl}/hiw____.htm`} />
        <MainNavItem title="Favorites" url={`${siteUrl}/favorites____.htm`} />
        <MainNavItem title="My account" url={`${siteUrl}/ai____.htm`} />
        <MainNavItem title="Offers for you" url={`${siteUrl}/foryou____.htm`} />
        <MainNavItem title="FAQs" url={`${siteUrl}/faq____.htm`} />
        <MainNavItem title="Contact us" url={`${siteUrl}/contact____.htm`} />
        <MainNavItem
          title="Southwest.com&reg;"
          url="https://www.southwest.com/rapidrewards/overview?int=MALL-RRHP-LINK"
          target="_blank"
          rel="external"
        />

        <MainNavItem
          {...addExternalAttributesIfNeeded(privacyPolicyUrl, siteUrl)}
          title="Privacy policy"
          url={`${privacyPolicyUrl}`}
        />
        <MainNavItem
          {...addExternalAttributesIfNeeded(termsAndConditionsURL, siteUrl)}
          title="Terms"
          url={`${termsAndConditionsURL}`}
        />
        {enablePrivacyNoticeLink && (
        <MainNavItem
          title={<>Rapid Rewards Shopping<sup>&reg;</sup> terms and privacy notice</>}
          url={`${siteUrl}/p/privacy-notice.htm`}
        />
        )}
        {enableDonotSharePage && !userIsIdentified && (
        <MainNavItem
          title="Privacy preferences"
          url={`${siteUrl}/dps____.htm`}
        />
        )}
        {enableDonotSharePage && userIsIdentified && clientHostedDPSRedirectURL && (
          <MainNavItem
            title="Privacy preferences"
            url={clientHostedDPSRedirectURL}
            target="_blank"
          />
        )}
        {userIsIdentified && (
          <ShowOnMQ m l>
            <MainNavItem title="Log Out" url={`${siteUrl}/logoutUser.php?lUrl=/`} />
          </ShowOnMQ>
        )}
        {shouldShowButtonLink && (
          <MainNavItem newPillEnabled title="The Button" url={`${siteUrl}/button`} />
        )}
      </div>
    </div>
  );
}

Navigation.defaultProps = {
  sectionId: '10',
};

export default Navigation;
